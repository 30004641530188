import React from 'react'
import dynamic from 'next/dynamic';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
const MarkdownText = dynamic(() => import('../MarkdownText/MarkdownText'));
import Block from '../../adapters/helpers/Block';
const IconContainer = (props) => {
    const { document, isSmall } = props
    const containerData = document.fields
    const renderIconList = (data) => {
        return (
            <div className='icon-withText'>
                <img src={data?.fields?.iconImage?.fields?.asset?.fields?.file?.url}></img>
                <p>{data?.fields?.title}</p>
            </div>
        )
    }
    return (
        <div style={{ backgroundColor: containerData.backgroundColor }} className={containerData.containerClass}>
            <h2 className={'iconcontainer-heading'}>{containerData.title}</h2>
            <MarkdownText className={'iconcontainer-description'} value={containerData.description} />
            <div className='ob-cards-wrapper'>
                {containerData.iconContainer.map((res, index) => {
                    if (res.fields.contentType == 'htmlBlock' && !isSmall) {
                        return (
                            <div key={index} className='ob-cards'>
                                {new Block(res).renderBlockFromDocument()}
                            </div>
                        )
                    }

                    return (
                        <div className='icon-item' key={index}>
                            {renderIconList(res)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default mediaQueryHOC(IconContainer)
